var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "loading"
  }, [_c('div', {
    staticClass: "loading-block"
  }, [_c('p', [_vm._v("E")]), _c('p', [_vm._v("D")]), _c('p', [_vm._v("U")]), _c('p', [_vm._v("T")]), _c('p', [_vm._v("E")]), _c('p', [_vm._v("S")]), _c('p', [_vm._v("T")])])]);

}]

export { render, staticRenderFns }